.card {
  position: initial;
  //box-shadow: $shadow-card;
  border: none;
  padding: 10px;

  .sub-title {
    padding-left: 6px;
    font-size: 14px;
    color: $support-color;
  }

  .card-head {
    padding-top: 20px;
    color: $oficial-blue;
    font-size: 18px;
    font-weight: $bold;
    span {
      padding-top: 5px;
    }
  }
}

.card-top-out-title {
  font-size: 16px;
  font-weight: 500;
  color: #4d5261;
}

.icon-card {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  .small {
    width: 38px;
    height: 38px;
    padding-top: 2px;
    padding-left: 3px;
  }
  .big {
    width: 30px;
    height: 30px;
    padding-left: 3px;
  }
}

.title-contrats {
  margin-top: 2px;
  color: $oficial-blue;
  font-size: 22px;
  font-weight: $bold;
}

.ver-mas {
  a {
    color: $blueberry !important;
    text-decoration: none;
    display: flex;
    &:hover {
      color: $blueberry !important;
      cursor: pointer;
      text-decoration: none;
    }
    img {
      margin-left: 5px;
    }
    .arrow {
      vertical-align: bottom;
    }
  }
}

.contt {
  background-color: white;
  padding: 1rem;
  font-size: 14px;
  display: flex;
  flex-flow: column;
}