/************************************************
 * INPUTS
************************************************/

.input_primary {
  padding: 10px !important;
  height: 37px;
  width: 100%;
  color: #4d4d4d;
  background-color: $base-color !important;
  border: 1px solid #7aabee !important;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  &:focus {
    outline: 1px solid #7aabee;
  }
  &.ng-invalid.ng-touched {
    border-color: $oficial-red !important;
    color: $oficial-red;
  }
}

/************************************************
 * SELECT
************************************************/

select {
  height: 37px;
  width: 100%;
  font-size: 14px !important;
  color: #4d4d4d !important;
  background-color: white;
  border: 1px solid #7aabee !important;
  box-shadow: none;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  filter: none !important;
  -webkit-filter: none !important;
  &:focus {
    outline: 1px solid #7aabee;
  }
}
