@font-face {
  font-family: 'Lira';
  src: url('../../fonts/LiraSansCompass-Light.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lira';
  src: url('../../fonts/LiraSansCompass-Medium.otf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lira';
  src: url('../../fonts/LiraSansCompass-Regular.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lira';
  src: url('../../fonts/LiraSansCompass-Bold.otf') format('truetype');
  font-weight: 700;
  font-style: bold;
}
