// - Primary Colors - //
$oficial-blue: #002fa7;
// - Accent colors - //

// - Blue
$blueberry: #0d61e7;

// - Blue links - //
$cian: #3bbfe2;

// - Secondary blue - //
$secondary-blue: #0d61e7;

// - corporative blue - //
$corporative: #7caaf3;
$border-blue: #7aabee;

//third blue
$headers-table: #0D61E7;

// - Green - //
$sea: #38a888;

// - secondary green - //
$tea: #b8e994;

// ofical-grey already exists, orange already exists, carnation already exists,

// COLORS FOR ADMIN TABLE //
$about-to-expire: #ffd600;
$updated: #52c41a;
$expired: #ff4d4f;
$notified: #1890ff;
$need-sign: #f970eb;

//TODO: Quitar
$allports: #002c4c;
$oxford-blue: #112132;
$degraded-blue: #3b4868;

//- Purple
//TODO: Quitar
$purple: #9887ff;

// - Grey
$oficial-grey: #bfbfbf;
$text-grey: #4d4d4d;
$bold-grey: #575757;
$light-grey: #eff2f6;
$dark-grey: #4d5261;
$bg-grey: #f1f3f5;

// TODO: Quitar
$silver: #e3e3e3;
$grey: #b9b9b9;
$medium-grey: #8b8b8b;
$degraded-grey: #d8dde9;
// - Yellow
// TODO: Quitar
$yellow-sunshine: #edbc6a;
$oasis: #fce1c5;
$watusi: #f1bfbf;

// - Green
$apple: #38a888;
$frog: #11ae21;
//TODO: Quitar
$aqua-green: #1f7e7f;

// - Purple
$purple-grape: #624b78;

// - Red
$oficial-red: #b5283d;
//TODO: Quitar
$carnation: #eb2f06;

// - White
$soft-white: #fafafa;
//TODO: Quitar
$honeydew: #d2ffca;
$white-smoke: #ebebeb;

// - Orange
$orange: #fa983a;
//TODO: Quitar
$dandelion: #ff843f;

// - Semantic Colors - //
$base-color: #fff;
$support-color: #171910;

// - Color by functionality - //

$brand-color: $oficial-red;

//- Backgrounds
$bg-main-color: $bg-grey;
$bg-alert-color: $carnation;
$bg-warning-color: $dandelion;
$bg-success-color: $apple;
$bg-info-color: $blueberry;
$bg-hover-table-color: $degraded-blue;
$bg-sending-color: $allports; //$oficial-blue;
$bg-tag-color: lighten($oficial-red, 12%);

$bg-created-color: $honeydew;
$bg-accepted-color: darken($honeydew, 4%);

$bg-paid-color: $oasis;
$bg-scanned-color: darken($oasis, 4%);

$bg-delivering-color: $white-smoke;
$bg-collected-color: darken($white-smoke, 4%);

$bg-failed-color: $watusi;
$bg-rejected-color: darken($watusi, 4%);
$bg-cancelled-color: darken($watusi, 8%);
//- Buttons
$btn-main-color: $oficial-blue;
$btn-hover-color: darken($oficial-red, 5%);
$btn-disabled-color: $grey;
//- Border
$border-main-color: $oficial-blue;
$border-checkbox-color: $bold-grey;
$border-alert-color: $carnation;
$border-calendar-color: $silver;
$border-table-color: $grey;
$border-input-modal: $medium-grey;

//- Text
$text-label-color: $degraded-blue;
$text-main-color: $oficial-blue;
//$text-bold-color: $bold-grey;
$text-title-color: $allports; //$oficial-blue;
$text-subtitle-color: $blueberry;
$text-input-color: $oficial-blue;
$text-alert-color: $carnation;
$text-warning-color: $dandelion;
$text-success-color: $apple;
$text-notification-color: $blueberry;
$text-disabled-color: $bold-grey;
$text-modal-color: $allports;

//- Menu
$bg-selected-color: $grey;

//- Modal
$input-modal-color: $silver;
$input-modal-footer: $silver;

//- Shadows
$shadow-header: 0px 1px 15px 0px rgba(0, 47, 167, 0.1);
$shadow-general: 0px 7px 64px rgba($support-color, 0.03);
$shadow-inputs: -2px 2px 10px rgba(181, 181, 181, 0.5);
$shadow-box: 0px 0px 10px rgba($support-color, 0.07);
$shadow-card: 0px 2px 20px rgba(0, 0, 0, 0.1);
$shadow-hover: 0px 6px 40px rgba($support-color, 0.2);
$shadow-notification: 0px 7px 64px rgba($oficial-blue, 0.07);
$shadow-buttons: 0px 6px 40px rgba($oficial-blue, 0.2);
$shadow-tabs: inset 0px -1px 0px rgba(208, 201, 214, 0.4);
