//- Container site
$max-width: 1600px;
$desktop-medium: 1200px;

//- Folders
$base: '/assets/';
$images: $base + 'images/';
$backgrounds: $images + 'backgrounds/';
$icons: $base + 'fonts/btg/';

//- Typography
  //- e.g.
$icon-typography: 'btg' !default;
  
//- Weight Typography
//- e.g.
$thin : 200;
$light : 300;
$regular: 400;
$medium : 500;
$semi-bold: 600;
$bold : 700;

//- Breakpoints
$desktop: $max-width;
$tablet-landscape: 1024px;
$tablet-portrait: 960px;
$tablet: 768px;
$mobile: 600px;
$mobile-landscape: 480px;
$mobile-portrait: 320px;

//- Grid
$col-1: 8.33333333%;
$col-2: 16.66666667%;
$col-3: 25%;
$col-4: 33.33333333%;
$col-5: 41.66666667%;
$col-6: 50%;
$col-7: 58.33333333%;
$col-8: 66.66666667%;
$col-9: 75%;
$col-10: 83.33333333%;
$col-11: 91.66666667%;
$col-12: 100%;

//- Icons
$icon-arrow-right-hover: "\e900";
$icon-arrow-left-hover: "\e902";
$icon-arrow-right: "\e90b";
$icon-arrow-left: "\e90c";
$icon-exit: "\e905";
$icon-urns: "\e906";
$icon-conditions: "\e907";
$icon-rules: "\e908";
$icon-delete: "\e909";
$icon-edit: "\e90a";
$icon-search: "\e90d";
$icon-add: "\e90e";