// clase para la tabla de admin
.dashboard {
  vertical-align: middle;
  thead {
    border-collapse: collapse;
    background: $oficial-blue;
    color: white;
    th {
      padding: 16px;
      font-size: 12px;
      font-weight: 500;
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        img:hover {
          cursor: pointer;
        }
      }
      &:last-child {
        div {
          justify-content: center;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        font-size: 12px;
        font-weight: 400;
        color: #4d4d4d;
        padding: 16px !important;
        .status-container {
          display: flex;
          align-items: center;
          .status {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 8px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
          .about-to-expire {
            background: $about-to-expire;
          }
          .updated {
            background: $updated;
          }
          .expired {
            background: $expired;
          }
          .notified {
            background: $notified;
          }
          .need-sign {
            background: $need-sign;
          }
        }
        .pdf {
          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

table thead tr th {
  font-size: 16px;
  color: $medium-grey;
  font-family: $main-typography;
  text-transform: none;
  font-weight: $regular;
  white-space: nowrap;
  text-align: left;
}

table tbody tr td {
  font-size: 16px;
  color: $support-color;
  font-family: $main-typography;
  text-transform: none;
  font-weight: $medium;
  white-space: nowrap;
  border-top: 1px solid lighten($grey, 15%) !important;
  &:hover {
    //box-shadow: $shadow-box;
    td {
      a {
        color: $base-color;
      }
    }
  }
}

.right-title thead tr th {
  text-align: right;
}

/* .hoverSelectRow {
  table tbody {
    tr {
      &:hover {
        td {
          background: $bg-hover-table-color;
          color: $base-color;
          font-weight: $light;
        }
      }
    }
  }
} */

.borden-left-table {
  font-weight: $medium !important;
  border-bottom: none !important;
}

.borden-bottom-table th {
  border-bottom: 1px solid $medium-grey !important;
}

.scroll-table {
  overflow-y: auto;
  overflow-x: auto;
}

.withoutData {
  font-weight: 300;
  margin-top: 10px;
  color: $bold-grey;
}

.white-space {
  white-space: normal;
}

/* table tbody tr:first-child td {
  padding-top: 0;
} */

/* table transaccional */
.tablefondos thead tr{
  background-color: $secondary-blue;

}
.tablefondos thead tr th{
  font-size: 14px;
  color: $base-color;
  font-weight: 700;
  font-family: $main-typography;
  text-transform: none;
  text-decoration: none;
  vertical-align: middle;

}
.tablefondos tbody tr{
  border-color: rgba($color: #4D4D4D, $alpha: 1.0);
}
.tablefondos tbody tr > .fondo-item{
  font-size: 12px;
  color: $blueberry;
  text-decoration: underline;
}
.tablefondos tbody tr td{
  color: $text-grey;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
}
/* tabla ordenes */
.t-orders thead tr th{
  font-size:12px;
}