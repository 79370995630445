/* You can add global styles to this file, and also import other style files */
body {
  background-color: #f5f5f5;
}

.navbar {
  background-color: white;
}
//Imports

//Fonts
@import 'assets/scss/theme/fonts.scss';
//Icons
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');
//- Global config
@import 'assets/scss/config/_variables';
@import 'assets/scss/theme/_colors.scss';
@import 'assets/scss/theme/_typography.scss';
// - Theme styles
@import 'assets/scss/theme/compass.scss';
// - Components
@import 'assets/scss/components/animations';
@import 'assets/scss/components/cards';
@import 'assets/scss/components/table';
@import 'assets/scss/components/inputs';