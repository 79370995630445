/************************************************
 * SECTIONS
************************************************/
body {
  background: $bg-main-color;
  color: $text-main-color;
  font-family: $main-typography;
}

/************************************************
 * FORMS
************************************************/
label {
  color: $text-label-color;
  font-weight: $regular;
}

input[type='email'],
input[type='password'],
input[type='tel'],
input[type='text'],
input[type='phone'],
input[type='url'],
input[type='date'],
textarea {
  background: $base-color;
  border: 1px solid $border-blue;
  /* box-shadow: $shadow-general; */

  font-weight: $light;
  padding: 10px !important;
  font-family: $main-typography;
  /* filter: drop-shadow(-2px 2px 8px rgba(181, 181, 181, 0.5)) !important; */
  &:focus {
    box-shadow: $shadow-inputs;
    color: $text-input-color;
  }
  &.ng-invalid.ng-touched {
    border-color: $oficial-red;
    color: $oficial-red;
  }
}

input[type='date'] {
  font-weight: 400;
  color: $oficial-blue;
}

input[type='date'] {
  -webkit-text-fill-color: $text-grey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  background-color: $base-color !important;
  box-shadow: $shadow-general !important;
  color: $oficial-blue !important;
  font-weight: $regular !important;
  font-family: $main-typography !important;
  font-size: 18px !important;
  border: none !important;
  filter: drop-shadow(-2px 2px 10px rgba(181, 181, 181, 0.5)) !important;
  &:hover {
    box-shadow: $shadow-inputs;
    color: $text-input-color;
  }
  &:disabled {
    background-color: $grey !important;
    color: $base-color !important;
  }
  &.ng-invalid.ng-touched {
    border-color: $bg-alert-color !important;
    color: $base-color;
  }
  padding: 4px !important;
  font-family: $main-typography;
}

.form-select:disabled {
  background-color: #b9b9b9;
  color: $base-color !important;
}

.highlight {
  //background-color: $degraded-blue !important;
  margin-top: 0 !important;
  border: 1px solid $blueberry !important;
}

.custom_row {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  & > * {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }
}

.btn-default,
button,
input[type='button'],
input[type='submit'] {
  background: $btn-main-color;
  //box-shadow: $shadow-buttons;
  color: $base-color;
  /* &:hover {
    background: $btn-hover-color;
    color: $base-color;
  } */
  &:disabled {
    background: $btn-disabled-color;
    color: $base-color;
  }
  &.ghost {
    background: transparent;
    border: 1px solid $btn-main-color;
    color: $btn-main-color;
    &:hover {
      background: $btn-hover-color;
      border: 1px solid $btn-hover-color;
      color: $base-color;
    }
  }
  &.clear {
    background: transparent;
    color: $btn-main-color;
    &:hover {
      border-color: $btn-main-color;
    }
  }
  &.simple {
    background: transparent;
    color: $btn-main-color;
    &:hover {
      background: transparent;
      color: $text-main-color;
    }
  }
}

.buttton-eye {
  float: right;
  margin-right: 10px;
  margin-top: -45px;
  position: relative;
  z-index: 2;
  color: $bold-grey;
  cursor: pointer;
}

.btn-primary {
  background: $oficial-blue;
  border: none;
  &:hover {
    border: none;
  }
  &:disabled {
    background: $btn-disabled-color;
    border: none;
  }
  &.btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    background: $oficial-blue;
    border: none;
  }
}

.btn-secondary {
  background: $medium-grey;
}

.btn-alternative-alternative{
  background: $oficial-blue;
  color: $base-color;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $base-color;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;

}

.btn-alternative {
  background: $base-color;
  color: $oficial-blue;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid $oficial-blue;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  &:hover {
    border: 1px solid $oficial-blue;
    background: $base-color;
    color: $oficial-blue;
  }
}

.btn-export {
  background: $soft-white;
  color: $blueberry;
  box-shadow: -2px 2px 10px rgba(181, 181, 181, 0.5);
  border-radius: 5px;
  &:hover {
    background: $blueberry;
    color: $soft-white;
  }
  .icon {
    vertical-align: bottom;
  }
}

.switch {
  input[type='checkbox'] {
    &:checked {
      + label {
        background: $btn-main-color;
        span {
          background: darken($base-color, 8%) !important;
        }
      }
    }
  }
  label {
    background: $btn-main-color;
    span {
      background: $base-color;
    }
  }
}

.opacity_app {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}

/************************************************
 * FONTS
************************************************/

.red {
  color: $oficial-red !important;
}
.green {
  color: $frog !important;
}
.yellow {
  color: $yellow-sunshine;
}

.spanTitle {
  color: $oficial-blue;
  font-size: 18px;
  font-weight: $medium;
}

.spanSubTitle {
  color: $oficial-blue;
  font-size: 14px;
  font-weight: $medium;
}

.spanAlternativeTitle {
  color: $dark-grey;
  font-size: 16px;
  font-weight: $regular;
}

.spanAlternativeSubTitle {
  color: $dark-grey;
  font-size: 14px;
  font-weight: $regular;
}

.oficial-blue {
  color: $oficial-blue !important;
}

.second-blue {
  color: $blueberry !important;
}

.text-grey {
  color: $text-grey;
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.weight-300 {
  font-weight: $light;
}

.weight-400 {
  font-weight: $regular;
}

.weight-500 {
  font-weight: $medium;
}

.weight-700 {
  font-weight: $bold;
}

/************************************************
 * GENERAL
************************************************/

.title {
  font-size: 20px;
  color: $oficial-blue !important;
  font-weight: $medium;
}

.subtitle {
  font-size: 18px;
  font-weight: $regular;
  color: $oficial-blue;
  display: block;
}

.contract-name {
  margin: 25px 0px;
  label {
    color: $degraded-blue;

    font-weight: $light;
    font-size: 18px;
  }
}

/*Especial sizes*/

.mt-25 {
  margin-top: 25px;
}

.w-45 {
  width: 45%;
}

.mb-100 {
  margin-bottom: 110px;
}

@media (max-width: 600px) {
  .w-45 {
    width: 100%;
  }
}

/*Especial sizes*/

.card-trans {
  position: initial;
  padding: 10px;
}

.card-title-button {
  position: relative;
  min-width: 200px;
  span {
    position: absolute;
    bottom: 0;
  }
}

.fadeIn {
  animation: fadeIn 2s;
  -moz-animation: fadeIn 2s; /* Firefox */
  -webkit-animation: fadeIn 2s; /* Safari and Chrome */
  -o-animation: fadeIn 2s;
}

.pointer {
  cursor: pointer;
}
/************************************************
 * FOOTER
************************************************/
.footer-login {
  min-height: 100px;
  position: relative;
  bottom: 0;
  width: 100%;
  background-color: $oxford-blue;
  span {
    color: $base-color;
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .footer-login {
    position: relative;
  }
}
//***GRID**//

@media (min-width: 992px) {
  .container {
    max-width: 1130px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1270px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1450px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1650px;
  }
}

/************************************************
 * COMPONENTS
************************************************/

// componente de tabla

btg-validate {
  .msg-alert {
    color: $text-alert-color;
  }
}

/* Breadcum */

ul.breadcrumb {
  padding: 12px 0px;
  list-style: none;
}
ul.breadcrumb li {
  display: inline;
  font-size: 12px;
  font-weight: $medium;
  color: #a6a6a6;
  &:last-child {
    color: #4d5261;
  }
}
ul.breadcrumb li + li::before {
  padding: 8px;
  color: #002fa7;
  content: '/\00a0';
}
ul.breadcrumb li a {
  color: #4d4d4d80;
  text-decoration: none;
}

// User options component

.user-options-container {
  width: 100%;
  margin-top: 10px;
  background-color: white;
  padding: 12px;
  z-index: 5;
  li {
    list-style-type: none;
    a {
      text-decoration: none;
      &:hover {
        color: #002fa7;
        cursor: pointer;
      }
      div {
        padding: 8px;
        img {
          height: 16px;
          padding-right: 8px;
        }
      }
    }
  }
}

// Manuales component
.link-manuales {
  text-decoration: none;
}

//Accordion

.accordion-body {
  background-color: $bg-grey;
  padding-left: 0px;
  padding-left: 0px;
}

.accordion-item {
  border: none;
}

.accordion-flush,
.accordion-item {
  margin-bottom: 10px;
}

.notification {
  display: none;
  position: fixed;
  z-index: 10000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0006;
}

//Tooltip
.tooltipp .tooltiptextt {
  visibility: hidden;
  width: 185px;
  background-color: #000000c9;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px 10px;
  position: absolute;
  z-index: 1;
  margin-left: 175px;
  margin-top: -20px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltipp:hover .tooltiptextt {
  visibility: visible;
}

/* Modal Content */
.notification-content {
  background-color: #fefefe;
  margin-top: auto;
  padding: 25px;
  border: 1px solid #888;
  border-radius: 8px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-180%);

  .title-notification {
    font-size: 18px;
    color: $oficial-blue;
    margin-top: 20px;
  }
}

.change-password {
  input[type='text'],
  input[type='password'] {
    background: $bg-grey;
  }

  button {
    height: 60px;
  }
}

.password-requirements {
  ul li,
  span {
    color: $medium-grey;
    font-size: 12px;
    font-weight: $regular;
  }
}

.change-password-header {
  padding-top: 20px;
  span {
    font-size: 18px;
    color: $bold-grey;
    font-weight: $medium;
    line-height: 50px;
    margin-left: 20px;
  }

  img {
    width: 15px;
    margin-left: 18px;
  }
}

.accordion-button {
  font-family: $main-typography;
  background: rgba(249, 249, 249, 1);
  color: $degraded-blue;
  font-weight: $regular;
  font-size: 16px;
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
  &:hover {
    //background: none;
    color: $degraded-blue;
  }
  &:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.125);
  }
  &:not(.collapsed) {
    //background: none;
    color: $degraded-blue;
  }
}

.accordion-button.collapsed::after,
.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
}

.accordion-button:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125);
}

.tabs {
  background: $base-color;
  box-shadow: $shadow-tabs;
  ul {
    li {
      a {
        color: $text-input-color;

        &:hover,
        &.active {
          color: $text-main-color;
          &:before {
            background: $btn-main-color;
          }
        }
      }
    }
  }
}

body {
  &.loading {
    animation: changeColor 4s infinite 2s;
    background-color: $base-color;
  }
}

.circle {
  background-color: $brand-color;
  border: 4px solid $brand-color;
}

.shadow {
  animation: moveShadow 4s infinite 2s;
  background-color: $base-color;
}

@keyframes moveShadow {
  0% {
    transform: translateX(150px);
    background-color: $base-color;
  }
  50% {
    transform: translateX(-10px);
    background-color: $bg-main-color;
  }
  100% {
    transform: translateX(-170px);
    background-color: $base-color;
  }
}

@keyframes changeColor {
  0% {
    background-color: $base-color;
  }
  50% {
    background-color: $bg-main-color;
  }
  100% {
    background-color: $base-color;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/************************************************
 * LINKS
************************************************/
a {
  color: $text-main-color;
  &:hover {
    color: $btn-hover-color;
  }
}
/************************************************
 * UI ELEMENTS
************************************************/

/************************************************
 * TEMPLATE
************************************************/
// - Navbar
.header-shadow {
  box-shadow: $shadow-header;
}

.margin-container {
  margin-top: 69px;
}

.logo {
  margin: 5px 20px;
}

.user {
  color: $oficial-blue;
  padding-right: 20px;
  border-right: 1px solid $oficial-blue;
  label {
    color: $oficial-blue;
    font-weight: $regular;
    font-size: 14px;
    vertical-align: bottom;
  }
  img {
    height: 14px;
    margin-right: 12px;
  }
}

.options {
  display: none;
  position: absolute;
  top: 38px;
  width: 208px;
  &:hover {
    display: block;
  }
}

.user-options {
  padding-left: 16px;
  padding-right: 50px;
  color: $oficial-blue;
  img:hover {
    cursor: pointer;
  }
}

.user-options:hover + .options {
  display: block;
}

@media (max-width: 600px) {
  .logo {
    margin: 5px 8px;
  }
  .user {
    padding-right: 5px;
  }
  .user-options {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.country {
  font-weight: $medium;
  font-size: 14px;
  color: $degraded-blue;
  vertical-align: bottom;
}

.balance {
  padding-left: 20px;
  margin-right: 18px;
  img {
    padding-right: 8px;
    height: 20px;
  }
}

aside {
  nav {
    ul {
      li {
        border-bottom-color: $base-color !important;
        a {
          color: $base-color;
          font-weight: $light;
          &.active,
          &:hover {
            color: lighten($btn-hover-color, 20%);
          }
        }
      }
    }
  }
}

.element-list {
  background: white;
  padding: 14px;
  color: $degraded-blue;
  box-shadow: $shadow-box;
  border-radius: 8px;
  height: 52px;
  label {
    margin-left: 10px;
  }
}

.navbar {
  position: fixed;
  z-index: 2;
}

.phone {
  display: none !important;
}

.user-mobile {
  margin-left: 15px;
  margin-right: 6px;
}

.margin-country {
  padding-bottom: 12px;
}

@media (max-width: 600px) {
  .bigger {
    display: none !important;
  }
  .phone {
    display: flex !important;
  }
}

/************************************************
 * PAGES
************************************************/

/*
*Home page
*/

.cardsContainer {
  gap: 20px;
}

.bannerContainer {
  height: 415px;
  color: white;
  background-image: url('../../images/banner.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  div {
    padding: 40px;
    gap: 30px;
  }
}

@media (max-width: 600px) {
  .bannerContainer {
    height: 165px;
  }
}

.cardProductContainer {
  flex: 1 0 23%;
}

.cardProduct {
  background: $base-color;
  padding: 0;
  text-align: center;
  height: 100%;
  border-radius: 5px;
  cursor: pointer;

  .spanTitle {
    color: #002fa7;
  }

  img {
    height: 46px;
    width: 46px;
    margin: 2rem 0;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}

.public-pages {
  background-image: url('../../images/fondo-login.png');
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  h1 {
    font-size: 20px !important;
    margin-bottom: 8px;
    color: $oficial-blue;
    font-weight: $medium;
  }
  .login-data {
    font-size: 14px;
    color: $bold-grey;
    margin-bottom: 25px;
    font-weight: $regular;
  }
  a {
    color: $blueberry;
    text-decoration: none;
  }
  label {
    color: $oficial-blue;
    margin-bottom: 8px;
  }

  .margin-bottom-forgot {
    margin-bottom: 35px;
  }

  button {
    height: 55px;
  }

  .space-card {
    padding-top: 100px;
  }

  .card-login {
    background-color: $base-color;
    padding: 30px 30px 20px 30px;
  }

  .logo {
    max-width: 200px;
    padding-top: 10px;
    position: absolute;
  }

  @media (max-width: $mobile) {
    .logo {
      padding-left: 0px;
    }
  }
}

.logo_phone {
  position: absolute;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 200px;
      margin-top: 10px;
    }
  }
}

.buttons_code_public {
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 10px;
  }
}

.executive {
  font-size: 16px;
  font-weight: $medium;
  color: $bold-grey;
}

.not-found {
  background-color: $base-color;
  height: 100vh;
  color: $oficial-grey;

  .box {
    padding-top: 20vh;
  }
  .fourofour {
    color: $medium-grey;
    font-size: 80px;
  }
  .not {
    font-size: 50px;
  }

  .please,
  .sorry {
    font-size: 30px;
  }
  a {
    color: $degraded-blue;
    font-size: 20px;
  }
}
/************************************************
 * MEDIA QUERIES
************************************************/

@media (min-width: $tablet-portrait) {
  aside {
    nav {
      background: $base-color;
      box-shadow: $shadow-general;
      ul {
        li {
          border-bottom-color: transparent;
          a {
            color: $text-main-color;
            &.active,
            &:hover {
              background: $btn-main-color;
              color: $base-color;
            }
          }
        }
      }
    }
  }

  .total {
    p {
      strong {
        font-size: 1.6em;
      }
    }
  }
}

@media (max-width: $tablet) {
  .total {
    p {
      font-size: 1em;
      strong {
        font-size: 1.4em;
      }
    }
  }
}

@media (min-width: $mobile) {
  .mobil {
    display: none !important;
  }

  .desktop {
    display: initial !important;
  }
}

@media (max-width: $mobile) {
  .mobil {
    display: initial !important;
  }

  .desktop {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .padding-container-left {
    padding-left: 90px !important;
  }
}

@media (min-width: 1800px) {
  .padding-container-left {
    padding-left: 0px !important;
  }
}

@media (max-width: $mobile) {
  /*   table {
    thead {
      background: $base-color;
      border-radius: 8px 0 0 8px;
      box-shadow: $shadow-box;
      tr {
        th {
          border-bottom: 1px solid $bg-main-color;
        }
      }
    }
    tbody {
      box-shadow: none;
      tr {
        td {
          background: transparent;
          border-bottom: none;
          border-right: 1px solid $border-main-color;
          border-top: none;
          &:last-child {
            width: auto !important;
          }
        }
        &:last-child {
          td {
            border-right: none;
          }
        }
      }
    }
  }
  .total {
    p {
      font-size: 1.4em;
      strong {
        font-size: 2.4em;
      }
    }
  } */
}
